$primary-color: #b23029 !default;
$secondary-color: #e1e1e1 !default;
$violet-color: #405189;
$white-color: #fff !default;
$violet-dark: #1f294a;

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: $violet-color;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: $violet-dark;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
}

.form_box1 {
  border: 2px solid green;
}

html,
body,
#root {
  // font-family: "Lato", sans-serif !important;
  height: 100%;
  background-color: #fff;
}

a {
  text-decoration: none;
  display: inline-block;
}

.customFormHeight {
  height: 45px !important;

  .customFormHeight {
    height: 45px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
select {
  border: solid 1px #c5c5c5;
  border-radius: 3px;
  padding: 3px 7px;
}
select:focus,
input:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}
button,
input {
  outline: none !important;
  box-shadow: none !important;
}

/* CSS */
// .btn {
//   appearance: none;

//   border-radius: 6px;
//   box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
//   box-sizing: border-box;
//   color: #fff;
//   cursor: pointer;
//   display: inline-block;
//   font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
//     sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 20px;
//   padding: 6px 16px;
//   position: relative;
//   text-align: center;
//   text-decoration: none;
//   user-select: none;
//   -webkit-user-select: none;
//   touch-action: manipulation;
//   vertical-align: middle;
//   white-space: nowrap;
// }

// .btn:focus:not(:focus-visible):not(.focus-visible) {
//   box-shadow: none;
//   outline: none;
// }

.btn-export {
  background-color: #2c974b;
}

.btn-export:hover {
  color: #fff;
}

.btn:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.btn:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.btn:active {
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

// .btn-primary {
//   background-color: $primary-color !important;
//   border: 1px solid $primary-color !important;
// }

.btn-link {
  color: $primary-color;
}

.login-box {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  height: 400px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  padding: 2rem;

  img {
    width: 120px;
  }

  button {
    padding: 0.8rem;
    border-radius: 50px;
    font-weight: 600;
  }
}

// Layout
.erp-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  // > .container-fluid {
  //   margin-bottom: 126px;
  // }
}

.dashboard_wrapper {
  padding: 50px;

  .dashboard_infobox {
    margin-top: 50px;

    .box {
      padding: 40px;
      text-align: center;
      border-radius: 15px;
      background-color: #f2f2f2;

      i {
        color: $primary-color;
        font-size: 2rem;
      }

      h5 {
        font-size: 3rem;
        margin: 20px 0;
        font-weight: 700;
        line-height: 1;
      }

      a.btn {
        background-color: $violet-color;

        &:hover {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
}

// Layout End

.login-page {
  background-image: url("./images/login.jpeg");
  background-position: 50%;
  background-size: cover;
  height: 380px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  .bg-overlay {
    background: linear-gradient(90deg, #364574, $violet-color);
    opacity: 0.9;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .shape {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;

    svg {
      fill: $secondary-color;
      height: auto;
      width: 100%;
    }
  }
}

.brij-navbar {
  background-color: $violet-dark;
  box-shadow: 0 2px 10px #0000001f;
  .navbar-brand {
    margin: 0;
    padding: 3px 15px;
    background-color: #fff;

    img.logo-main {
      height: 44px;
    }
  }

  .dropdown-toggle.nav-link {
    color: #000;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .navbar {
    padding: 0;
    .profile {
      padding-right: 15px;
      .dropdown-menu.show {
        position: absolute;
        right: 0 !important;
        left: auto !important;
      }
    }
  }

  .navbar-nav {
    gap: 15px;
    align-items: center;
    margin-left: 30px;

    > a,
    .nav-item .dropdown > a.nav-link {
      color: #fff;
      padding: 0 10px;
    }

    .nav-link {
      background-color: transparent;
      color: #fff;
    }

    .nav-item.dropdown a.nav-link {
      &:hover {
        color: #fff;
      }

      &:focus {
        outline: none;
        color: #fff;
      }
    }

    a.dropdown-toggle {
      padding: 10px;
    }

    img.profile_pin {
      height: 30px;
      margin-right: 15px;
    }
  }

  .navbar-nav {
    .dropdown-menu {
      padding: 0;
      border-radius: 0;
      top: 90%;
      &.show {
        position: absolute;
        left: 0 !important;
        right: auto !important;
      }
      a.dropdown-item {
        padding: 7px 15px;
        border-bottom: solid 1px #ddd;
        a {
          color: #222;
        }
        &:last-child {
          border: none;
        }
        &:hover {
          background-color: $violet-color;
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
    }
  }

  .navbar-light .navbar-nav .show > .nav-link {
    color: #fff;
  }
}

.page-title {
  h4 {
    font-weight: 600;
  }
}

.custom-daterange-picker {
  width: 100%;
  position: relative;

  .custom-date-input {
    border: 1px solid #ced4da;
    cursor: pointer;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 0.25rem 0.75rem;
    position: relative;
    .custom-date-icon {
      background-color: $violet-color;
      width: 22px;
      height: 22px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 6px;
      img {
        height: 20px;
      }
    }
  }

  .modal_body {
    width: 880px !important ;
  }

  .custom-daterange-popup {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 9;
    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }
    .picker-toggler {
      background-color: $white-color;

      button.btn-link {
        text-decoration: none;
        color: $violet-color;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

.custom-datatable-component {
  .rdt_TableHeadRow {
    background-color: $violet-color;
    .rdt_TableCol {
      padding: 0;
      font-size: 12px;
      line-height: 1;
      font-weight: 300;
    }
  }
  div[data-tag="allowRowEvents"] {
    overflow: visible;
  }
  select.grid_select_input {
    border: solid 1px #c0c0c0;
    padding: 5px 7px;
    font-size: 12px;
    background-color: #bbbbbb17;
    border-radius: 3px;
    cursor: pointer;
    text-transform: capitalize;
    width: 130px;
  }
  p {
    margin: 0;
    &.grid_select_value {
      text-align: left;
      border: solid 1px #c0c0c0;
      padding: 7px 7px;
      font-size: 12px;
      background-color: #bbbbbb17;
      border-radius: 3px;
      cursor: pointer;
      text-transform: capitalize;
      min-width: 130px;
    }
  }
}

.customBtn_p button {
  border: none;
  background: #ca0e0e;
  border-radius: 3px;
  padding: 4px 8px;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
  margin: 6px 0;
}

.customBtn_r button {
  border: none;
  background: $violet-color;
  border-radius: 5px;
  padding: 8px;
  color: white;
}
input[type="file"] {
  position: relative;
  width: 100%;
  padding: 10px;
  padding-left: 130px;
  font-size: 15px;
}
input[type="file"]::before {
  content: "Upload ticket";
  position: absolute;
  background-color: #1f8aa6;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
}
input[id="import"]::before {
  content: "Import Excel file";
  position: absolute;
  background-color: #fafafa;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
  color: #707070;
  border: solid 1px #dfdfdf;
  // height: 29px;
  line-height: 1.7;
}
input[type="file"]::-webkit-file-upload-button {
  opacity: 0;
  font-size: 0;
  display: none;
}

.action_select_tab {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 15px;
  }
  .form-select {
    border: solid 1px #c5c5c5;
    margin: 5px 0;
    padding: 0px 10px;
    background-color: rgb(51, 51, 253);
    width: 72px;
    color: white !important;
  }
}

.submenu-header {
  padding: 0.8rem;
  background-color: #f9f9f9;

  ul {
    margin: 0;
    display: flex;
    align-items: center;

    li {
      a {
        text-decoration: none;
        font-size: 14px;
        color: #000;
        transition: all 0.1s;
        border-radius: 3px;

        &:hover {
          background-color: #000;
          color: #fff !important;
        }

        &.active {
          background-color: #000;
          color: #fff !important;
        }
      }
    }
  }
}

.loader {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

.application_wrapper {
  h5 {
    margin: 0;
    margin-right: 25px;
    font-weight: 700;
    font-size: 1.3em;
    text-transform: uppercase;
  }

  .application_tabs {
    ul.nav {
      border: none;
      align-items: center;

      a {
        border: solid 1px #dff0d8;
        border-bottom: none;
        color: #000;
        font-size: 15px;
        padding: 9px 13px;
        line-height: 1;

        &.active {
          background-color: #dff0d8;
          color: #000;
          font-weight: 500;
        }

        &:hover {
          border: solid 1px #dff0d8;
          border-bottom: none;
        }
      }
    }
  }

  .tab-content {
    .application_form_wrapper {
      background-color: #dff0d8;
      border-radius: 0;
      padding: 12px 0 6px;
      position: relative;
      .notificationButton {
        position: absolute;
        bottom: 100%;
        right: 0;
        background-color: #198754;
        color: #fff;
        padding: 4px 10px;
        line-height: 1;
        border-radius: 3px;
        font-size: 14px;
        margin-bottom: 6px;
      }
    }
  }
}

.table-responsive {
  margin-top: 13px;
  border-radius: 10px;
  overflow: hidden;
  .grid_table_wrapper {
    .rdt_TableCell {
      font-size: 12px;
      padding: 0 6px;
      font-weight: 500;
      line-height: 1;
    }
    div[role="columnheader"] {
      border-right: solid 1px transparent;
      span {
        display: none;
      }
    }
    > div {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 0;
      nav {
        background-color: transparent;
        border-radius: 0 0 5px 5px;
        font-weight: 600;
        min-height: inherit;
      }
    }
    > div:first-child {
      height: calc(100vh - 310px);
      position: relative;
      overflow-y: auto;
      margin-bottom: 0;
    }
  }
  &.formRow3 {
    .grid_table_wrapper {
      > div:first-child {
        height: calc(100vh - 347px);
      }
    }
  }
}

.application_action {
  option[data-content] {
    background-color: red;
  }
}

.preview_table_wrapper {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #fff;

  .title_box {
    text-align: center;
    background-color: rgb(18, 173, 228);
    color: white;

    h4 {
      color: $primary-color;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .dbtnp {
    background-color: #3838ce;
    color: white;
    border-radius: 3px;
    margin-left: 32px;
  }
  .viewdata {
    padding: 5px;
    border-right: solid 1px #cecece;
    border-bottom: solid 1px #cecece;
    font-size: 14px;
    text-transform: uppercase;
    &.orange,&.suborange {
      grid-column: 1/3;
      background-color: orangered;
      color: white;
    }
    &.suborange {
      background-color: $secondary-color;
      color: $violet-dark;
      
    }
  }

  table {
    width: 100%;
    margin: 3px auto 0;
    overflow: hidden;
    height: 100%;

    // thead {
    //   background-color: $violet-color;
    //   color: #fff;
    // }

    td,
    th {
      border: solid 1px #dadada;
      padding: 10px 15px;
      .viewdata {
        border: none;
        padding: 0;
      }
    }

    // tbody {
    //   tr:nth-child(2n+1) {
    //     background-color: red;
    //   }
    // }
  }
  .tableHead {
    background-color: #0000ff;
    margin-top: 10px;
    padding: 5px;
    h5 {
      color: #fff;
      margin: 0;
    }
  }
}
.settings_wrapper {
  // background-color: pink;
  ul.nav-tabs {
    margin-bottom: 0;
    button {
      color: #707070;
      border: none;
      &.active {
        background-color: #dff0d8;
        border-bottom: none;
        color: #000;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .tab-content {
    .tab-pane {
      & > div:last-child {
        height: calc(100vh - 430px);
        overflow-y: auto;
      }
      &.active {
        background-color: #dff0d8;
        padding: 20px;
      }
    }
  }
}

.datatab_d {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // border: solid 1px #000;
  overflow-x: auto;
}

.bg_pinky {
  background-color: rgb(255, 102, 0);
  color: white;
}

// .btn-primary {
//   box-sizing: border-box;
//   margin-right: 0.333em;
//   margin-bottom: 0.333em;
//   padding: 0.5em 1em;
//   border: 1px solid rgba(0, 0, 0, 0.3);
//   border-radius: 2px;
//   cursor: pointer;
//   font-size: 0.88em;
//   line-height: 1.6em;
//   color: #0f0f0f;
//   white-space: nowrap;
//   overflow: hidden;
//   background-color: rgba(0, 0, 0, 0.1);
//   background: linear-gradient(
//     180deg,
//     hsla(0, 0%, 90%, 0.1) 0,
//     rgba(0, 0, 0, 0.1)
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(230, 230, 230, 0.1)",EndColorStr="rgba(0, 0, 0, 0.1)");

//   text-overflow: ellipsis;
// }
footer {
  background-color: $violet-dark;
  padding: 10px 0;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // box-shadow: 0 -4px 10px rgb(99 99 99 / 20%);
  p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
  }
}

.Application_photo {
  border: 1px solid gray;
  width: 25%;
  margin: 5px;
}

input.hanuman {
  width: 75px;
}
input.hanuman2 {
  width: 75px;
}
.table__dttime {
  text-align: left;
  span {
    display: block;
    background-color: #36bea6;
    padding: 2px;
    color: #fff;
    font-size: 12px;
    margin: 4px 0 5px;
    border-radius: 3px;
    text-align: center;
  }
}
.tbl__mailsent {
  position: relative;
  background-color: #36bea6;
  color: #fff;
  padding: 1px 10px;
  font-size: 12px;
  overflow: visible !important;
  border-radius: 3px;
  sup {
    position: absolute;
    top: -7px;
    right: -7px;
    background-color: rgb(0, 99, 13);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
    width: 15px;
    height: 15px;
  }
}
.bigModal {
  max-width: 900px;
}
.custom-datatable-component div[data-tag="allowRowEvents"] {
  overflow: visible !important;
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.anchorColor {
  color: rgba(10, 2, 2, 0.812) !important;
}
.dropdmenu {
  position: relative;
  z-index: 2;
}
a {
  cursor: pointer;
}
.custom-datatable-component button:hover {
  color: #fff;
}
.custom-datatable-component button.column_btn {
  padding: 1px 5px;
  background: none;
  border: none;
  line-height: 1;
  color: #717171;
  font-size: 17px;
}
.custom-datatable-component button.column_btn:hover {
  background: none;
  color: #000;
}
.remarks_list_box {
  max-height: 250px;
  overflow-y: auto;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  .remarks_items {
    border-bottom: dashed 1px #a9a9a9;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .cont {
      flex: 4;
      p {
        font-size: 14px;
        color: #333;
        margin: 0;
      }
    }
    .datetime {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      span {
        font-size: 12px;
        color: #848484;
      }
    }
  }
  h3 {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
  }
}
.rdt_Pagination {
  background-color: #dff0d8;
}
.main_banner {
  width: 100%;
  height: 100%;
  header {
    padding: 10px;
    img.logo {
      height: 50px;
    }
  }
  .bg {
    height: calc(100vh - 70px);
    img {
      object-fit: cover;
    }
  }
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
}
.action_dropdown {
  button {
    padding: 8px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  &.show {
    overflow: visible !important;
  }
}

.flex-1 {
  flex: 1;
}

.rights_wrapper {
  margin-bottom: 60px;
}
.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
.card {
  background-color: transparent;
  border: none;
}
.card-body {
  padding: 0;
}
.govttablegrid {
  max-height: 300px;
  overflow-y: auto;
}
.settings_wrapper {
  margin-bottom: 100px;
}
span.grid_text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  display: inline-block;
  a {
    color: inherit;
  }
}
